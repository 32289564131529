import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { StaffSelector, RoleSelector } from '@cloudpivot-hermes/common-components';
import { OrgManageService } from '@/sevices';
let Test = class Test extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            companyName: '',
            staff: null,
            staff1: undefined,
            role: [],
            employees: [{
                    staff: null,
                    age: '',
                    sex: undefined,
                    id: this.getId(),
                }],
        };
        this.rules = {
            companyName: [
                {
                    required: true,
                    message: '所属公司不能为空',
                    trigger: 'blur',
                },
            ],
        };
    }
    get nameRule() {
        const nameRule = [
            {
                required: true,
                message: '姓名不能为空',
                trigger: 'blur',
            },
            {
                validator: value => /^[\u4e00-\u9fa5]+$/g.test(value),
                message: '姓名只支持中文汉字',
                trigger: 'blur',
            },
        ];
        return nameRule;
    }
    get staffRule() {
        const staffRule = [
            {
                required: true,
                message: '姓名不能为空',
                trigger: 'change',
            },
        ];
        return staffRule;
    }
    get ageRule() {
        const ageRule = [
            {
                required: true,
                message: '年龄不能为空',
                trigger: 'blur',
            },
            {
                validator: value => /^\d+$/g.test(value),
                message: '年龄只支持数字',
                trigger: 'blur',
            },
        ];
        return ageRule;
    }
    get sexRule() {
        const sexRule = [
            {
                required: true,
                message: '性别不能为空',
                trigger: 'change',
            },
        ];
        return sexRule;
    }
    get customService() {
        return {
        // treeApiUrl: '/api/commonservice/organization/department/tree',
        // treeApiMethod: this.treeApiMethod,
        // searchApiUrl: '/api/commonservice/organization/department/tree/search',
        // searchApiMethod: this.searchApiMethod,
        };
    }
    treeApiMethod(params, options) {
        return OrgManageService.treeApiMethod(params, options);
    }
    searchApiMethod(params, options) {
        return OrgManageService.searchApiMethod(params, options);
    }
    ok(value) {
        console.log(value);
    }
    showModal() {
        this.staffSelector.showModal();
    }
    showRole() {
        this.roleSelector.showModal();
    }
    okRole(value) {
        console.log(value);
    }
    getId() {
        return Math.floor(Math.random() * 1000) + (+new Date()) + '';
    }
    add() {
        this.form.employees.push({
            name: '',
            age: '',
            sex: undefined,
            id: this.getId(),
        });
    }
    del(index) {
        this.form.employees.splice(index, 1);
    }
    save() {
        const isValid = this.cform.validate();
        console.log(isValid);
    }
};
__decorate([
    Ref()
], Test.prototype, "cform", void 0);
__decorate([
    Ref()
], Test.prototype, "staffSelector", void 0);
__decorate([
    Ref()
], Test.prototype, "roleSelector", void 0);
Test = __decorate([
    Component({
        name: 'Test',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
            RoleSelector,
        },
    })
], Test);
export default Test;
